@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;

.layout-with-sidebar {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
  margin: 24px 0;
  width: 100%;

  .sidebar {
    display: none;
  }

  @include breakpoints.media-breakpoint-up(lg) {
    grid-template-columns: 100%;
    margin: 36px 0;

    &:global {
      &.has-sidebar {
        grid-template-columns: 25% 75%;
      }
    }

    .sidebar {
      display: block;
    }
  }
}
