@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;
@use '@styles/utilities';

.suggested-products-wrapper {
  display: flex;
  flex-direction: column;
  gap: functions.spacing(8);
  align-items: flex-start;
  color: var(--primary-dark);
  line-height: 1.4;
  padding-bottom: functions.spacing(12);

  &-with-title {
    padding-bottom: functions.spacing(6);

    @include breakpoints.media-breakpoint-up(md) {
      padding-bottom: functions.spacing(6);
    }
  }
}

.suggested-products-title {
  font-size: 1.25rem;
  font-weight: var(--font-weight-bold);
  line-height: 1.5;
  margin: functions.spacing(6, 0, 6, 0);

  @include breakpoints.media-breakpoint-up(md) {
    margin: functions.spacing(6, 0, 0, 0);
  }
}

.suggested-products-container {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include breakpoints.media-breakpoint-up(lg) {
    :global(.slider-container) {
      padding: 0 utilities.px-to-rem(80px);
    }
  }

  :global(.slider-container .slider-control-bottomcenter) {
    [role='tablist'] {
      top: functions.spacing(6);
      justify-content: center;
    }
  }
}

.single-item-wrapper {
  width: 100%;
  @include breakpoints.media-breakpoint-up(sm) {
    width: unset;
    flex-grow: 0.3;
  }
}
