@use '@styles/functions' as functions;
@use '@styles/mixins/breakpoints' as breakpoints;

.banner-tile-wrapper {
  height: 100%;
  color: var(--primary-dark);
  display: flex;
  font-size: 0.875rem;
  flex-direction: column;
  position: relative;

  .content-tiles {
    padding: functions.spacing(4, 2, 0);
    display: flex;
    flex-direction: column;
    gap: functions.spacing(4);
  }
}
