@use '@styles/mixins/breakpoints';

.phone-widget-wrapper-desktop {
  display: none;

  @include breakpoints.media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.phone-widget-wrapper-mobile {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #fff;
  @include breakpoints.media-breakpoint-up(lg) {
    display: none;
  }
}
