@use '@styles/functions' as functions;
@use '@styles/mixins/breakpoints' as breakpoints;

.suggested-product-tile-wrapper {
  color: var(--text-primary-dark);
  font-size: 0.75rem;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  text-align: center;

  @include breakpoints.media-breakpoint-up(md) {
    font-size: 1rem;

    &:hover {
      & > div {
        visibility: visible;
      }
    }
  }
}

.suggested-product-sub-wrapper {
  position: relative;
  cursor: pointer;

  &::before {
    content: '';
    padding-top: 50%;

    @include breakpoints.media-breakpoint-up(md) {
      display: block;
    }
  }
}

.square {
  justify-content: center;

  @include breakpoints.media-breakpoint-up(md) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.product-tile-popular-label {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  font-weight: var(--font-weight-bold);
  line-height: 1.43;
  text-transform: uppercase;
  align-items: center;
}

.product-tile-popular-label-2 {
  font-size: 0.75rem;
  font-weight: normal;
  text-transform: lowercase;
}

.badge-space-placeholder {
  padding: functions.spacing(1.8);
  @include breakpoints.media-breakpoint-up(md) {
    padding: functions.spacing(2.8);
  }
}

.image-gallery-wrapper {
  padding: 0;
  :global(.slider-container) {
    padding: functions.spacing(0, 10);
  }
  :global(.slide) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.image-container {
  // todo fix this, showing the image for carousel
  // display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.product-anchor {
  background-color: var(--background-secondary-light);
  width: 100%;
  justify-content: center;

  &::after {
    height: 0 !important;
  }
}

.footer-wrapper {
  color: var(--text-primary-dark);
  position: relative;
  margin-top: functions.spacing(2);
}

.product-tile-footer {
  color: var(--text-primary-dark);
  display: flex;
  flex-direction: column;
  gap: functions.spacing(1);
  font-weight: var(--font-weight-bold);
  padding: 0;
  align-items: center;

  :global(.product-price) {
    padding: 2px 0;
  }

  :global(.discount-wrapper) {
    order: 1;
  }

  :global(.offer-price) {
    order: 3;
    font-size: 1.25rem;
    font-weight: var(--font-weight-bold);
  }

  :global(.list-price) {
    order: 2;
    font-size: 0.875rem;
    padding: functions.spacing(0, 2.5);
    font-weight: var(--font-weight-bold);
  }
}

.product-tile-description {
  font-size: 0.875rem;
  margin-top: functions.spacing(4);
}

.product-tile-name {
  font-size: 0.875rem;
  font-weight: var(--font-weight-bold);
  line-height: 1.6;
  text-transform: capitalize;

  &.contact-lenses {
    font-size: 1rem;
    line-height: 1.33;
  }

  @include breakpoints.media-breakpoint-up(md) {
    &.contact-lenses {
      line-height: 1.43;
    }
  }
}

.product-tile-brand-name {
  font-size: 0.75rem;
  line-height: 1.33;
  font-weight: var(--font-weight-bold);
  text-transform: capitalize;

  @include breakpoints.media-breakpoint-up(md) {
    font-size: 0.875rem;
  }
}

.product-tile-header-wrapper {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.product-tile-attributes {
  font-size: 0.75rem;
  line-height: 1.6;
  text-transform: capitalize;

  @include breakpoints.media-breakpoint-up(md) {
    text-align: center;
  }
}
