.shoppable-teaser:global {
  &.light-contrast {
    :global(.shoppable-teaser-icon) {
      background-color: var(--secondary-dark);

      svg {
        fill: var(--primary-dark);
      }
    }
  }
}

.shoppable-teaser {
  position: absolute;
  top: 1rem;
  left: 1rem;

  .shoppable-teaser-icon {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--primary-dark);
    appearance: none;
    margin: 0;
    padding: 0;
    border: none;
    pointer-events: none;

    &:hover,
    &:focus {
      + .shoppable-teaser-products {
        opacity: 1;
        visibility: visible;
      }
    }

    svg {
      width: 1rem;
      fill: var(--primary-light);
    }
  }

  &:hover {
    .shoppable-teaser-products {
      opacity: 1;
      visibility: visible;
    }
  }

  .shoppable-teaser-products {
    position: absolute;
    display: flex;
    gap: 8px;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.3s, opacity 0.3s;
    line-height: 1.33;
    text-align: center;

    > a {
      font-size: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 4px;
      background-color: var(--secondary-light);
      box-shadow: 0 5.9px 11.8px 0 rgba(0, 0, 0, 0.16), 0 0 2.9px 0 rgba(0, 0, 0, 0.04);
      width: 100px;
      height: 90px;
      text-decoration: none;
      color: var(--primary-dark);
    }
  }
}
