.carousel-slide {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
