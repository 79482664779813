.full-page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-primary-light);
  opacity: 0.5;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}
