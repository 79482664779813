@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;
@use '@styles/utilities' as utilities;

.text-module {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  text-align: center;
  width: 100%;
  color: inherit;
  margin: 0;

  &:global {
    &[class*='light'] {
      color: var(--primary-light);
    }

    &.reset-gap {
      gap: 0;
    }

    &.left {
      align-items: flex-start;
      text-align: left;

      :global(.cta-container) {
        align-items: flex-start;

        @include breakpoints.media-breakpoint-up(xl) {
          justify-content: flex-start;
          margin-left: auto;

          a {
            width: fit-content;
          }
        }

        @include breakpoints.media-breakpoint-up(xxl) {
          margin-top: 0;
        }
      }
    }

    &.column-2 {
      @include breakpoints.media-breakpoint-up(xxl) {
        flex-direction: row;
        gap: 2.5rem;
        text-align: left;
        align-items: flex-end;
      }

      :global(.cta-container) {
        align-items: flex-start;

        @include breakpoints.media-breakpoint-up(xxl) {
          justify-content: flex-end;
          margin-left: auto;
          margin-top: 0;

          a {
            width: auto;
          }
        }
      }
    }
  }

  .teaser-text {
    //max-width: 400px;
    :global(.text-small) {
      font-size: utilities.px-to-rem(12px);
    }

    :global(.text-large) {
      font-size: utilities.px-to-rem(20px);
      line-height: 1.7;

      @include breakpoints.media-breakpoint-up(sm) {
        font-size: utilities.px-to-rem(40px);
        line-height: 1.4;
      }
    }

    a {
      color: var(--primary-dark);
      font-weight: var(--font-weight-bold);
    }

    h1 {
      font-size: utilities.px-to-rem(60px);
    }

    h2 {
      font-size: utilities.px-to-rem(40px);
    }

    h3 {
      font-size: utilities.px-to-rem(32px);
    }
  }

  :global(.cta-container) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    width: 100%;

    a {
      margin-top: functions.spacing(2);
      width: 100%;

      @include breakpoints.media-breakpoint-up(md) {
        width: auto;
      }
    }

    @include breakpoints.media-breakpoint-up(xl) {
      flex-direction: row;
    }
  }
}
