@use '@styles/functions' as functions;
@use '@styles/mixins/breakpoints' as breakpoints;

.cms-grid-banner-tiles {
  grid-column: auto / span 8;

  &-plp-one-tile {
    :global(.media-wrapper) {
      padding-bottom: 100%;
    }
    grid-column: 1 / span 8;
    @include breakpoints.media-breakpoint-up(md) {
      &-compact {
        grid-column: 5 / span 4;
      }
    }
  }

  &-plp-two-tiles {
    grid-column: 1 / span 8;

    :global(.media-wrapper) {
      padding-bottom: calc(100% / 2);
    }

    @include breakpoints.media-breakpoint-up(lg) {
      &-compact {
        grid-column: 5 / span 8;
      }
    }
  }
}
