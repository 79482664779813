@use '@styles/functions';
@use '@styles/mixins/breakpoints';

.product-price {
  display: flex;
  width: 100%;
  flex-grow: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: functions.spacing(1);
}

.markdown {
  padding: functions.spacing(0.5);
  border: solid 1px var(--secondary-dark);
  width: fit-content;
  font-weight: var(--font-weight-bold);

  @include breakpoints.media-breakpoint-up(md) {
    padding: functions.spacing(0.6);
  }
}
