@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;

.slider-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  .slider-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    @include breakpoints.media-breakpoint-up(md) {
      flex-direction: column;
      align-items: flex-start;
    }

    .slide {
      width: 20%;
      display: flex;
      margin: functions.spacing(0);
      justify-content: center;

      @include breakpoints.media-breakpoint-up(md) {
        width: 100%;
        margin: functions.spacing(0);
        justify-content: flex-start;
      }
    }

    .center-slide {
      justify-content: center;
    }
  }
}

.carousel-slide {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
